import api from './api'
import utils from './utils'



const adminFetchUser = (page, pageSize, searchText, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchUser, {
      page,
      pageSize,
      phone: searchText
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminUpdateUser = (id, level, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateUser, {
      id,
      level,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


export default {
  adminFetchUser,
  adminUpdateUser
}
