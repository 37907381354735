<template>
  <div>
    <!-- tool bar -->
    <div class="tool-bar">
      <div class="tb-1">
        <div style="display:inline-block;vertical-align:middle ">
          <a-select placeholder="仓库" v-model="searchArea" style="width: 200px" :allowClear="true">
            <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle">
          <a-input-search style="width: 300px" placeholder="搜索大类名称" v-model="searchText" enter-button
            @search="onSearch" />
        </div>
      </div>
      <div class="tb-2">
        <a-button @click="onAdd" type="primary">
          创建新大类
        </a-button>
      </div>
    </div>

    <!-- item form for add or edit  -->
    <ItemForm ref="itemForm" @onReset="onReset" @onConfirm="onConfirm" @onSet="onSet" title="分类管理">
      <div class="input-pad">
        <div class="input-label">选择仓库：</div>
        <a-select placeholder="仓库" v-model="form.belongAreaId" style="width: 200px" :allowClear="true">
          <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="input-pad">
        <div class="input-label">大类名称：</div>
        <a-input v-model="form.name" placeholder="请输入大类名称" />
      </div>
      <div v-if="!boolFirst">
        <div style="padding: 20px 0px">
          <div style="width:30%;display:inline-block">二级分类：</div>
          <div style="width:70%;display:inline-block;text-align:right">
            <a-button @click="onSecondAdd" type="primary">
              创建二级分类
            </a-button>
          </div>
        </div>
        <a-spin :spinning="boolSecond">
          <a-table :pagination="false" :columns="columnsS" :data-source="dataS" bordered style="background-color:white">
            <template slot="imgUrls" slot-scope="imgUrls">
              <div class="editable-row-operations">
                <div class="img-list" v-for="(item, index) in imgUrls" :key="index">
                  <image-tool v-if="item.response != undefined" :imgUrl="item.response.data"></image-tool>
                  <div v-else>-</div>
                </div>
              </div>
            </template>

            <template slot="status" slot-scope="text">
              <div>
                <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
                <a-tag v-else color="red"> 关闭 </a-tag>
              </div>
            </template>
            <template slot="operation" slot-scope="text, record">
              <div>
                <span>
                  <a @click="() => onStatus(record)">{{
                    record.status == "on" ? "下架" : "上架"
                  }}</a>
                </span>
                <span style="margin-left: 10px">
                  <a @click="onEditSecond(record)">编辑</a>
                </span>
              </div>
            </template>
          </a-table>
        </a-spin>

        <!-- second modal -->
        <ItemForm ref="itemFormSecond" @onReset="onResetSecond" @onConfirm="onConfirmSecond" @onSet="onSetSecond"
          title="二级分类">
          <div class="input-pad">
            <div class="input-label">二级分类名称：</div>
            <a-input v-model="formSecond.name" placeholder="请输入二级分类名称" />
          </div>
          <div class="input-pad">
            <upload-img ref="uploadImg" @onChangeImg="onChangeImg" placeholderText="上传图片" :totalLength="1"
              sizeText="尺寸（40 宽 x 40 高）" />
          </div>
        </ItemForm>


      </div>
    </ItemForm>

    <a-table :pagination="false" :columns="columns" :data-source="data" bordered style="background-color:white">
      <template slot="status" slot-scope="text">
        <div>
          <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
          <a-tag v-else color="red"> 关闭 </a-tag>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div>
          <span>
            <a @click="() => onStatus(record)">{{
              record.status == "on" ? "下架" : "上架"
            }}</a>
          </span>
          <span style="margin-left: 10px">
            <a @click="onEdit(record)">编辑</a>
          </span>
        </div>
      </template>
    </a-table>
    <div class="pagination-block">
      <PaginationBar ref="PaginationBar" :currentPageSize="currentPageSize" :total="total" @onChange="onChangePage" />
    </div>
  </div>
</template>

<script>
import PaginationBar from "@/components/PaginationBar.vue";
import StorageServices from "@/services/StorageServices";

import CateServices from "@/services/CateServices";
import ItemForm from "@/components/itemForm.vue";
import ImageTool from "@/components/imageTool.vue";
import UploadImg from "@/components/uploadImg.vue";

export default {
  components: {
    PaginationBar,
    ItemForm,
    ImageTool,
    UploadImg
  },
  data() {
    return {
      data: [],
      total: 0,
      columns: [
        {
          title: "所属仓库",
          dataIndex: "areaName",
          key: "areaName",
        },
        {
          title: "大类名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],

      dataS: [],
      columnsS: [
        {
          title: "二级分类名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "图片",
          dataIndex: "imgUrls",
          key: "imgUrls",
          scopedSlots: { customRender: "imgUrls" },
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      formSecond: {
        name: "",
        type: "level2",
        belongCateId: null,
        imgUrls: []
      },
      boolFirst: false,
      boolSecond: false,
      searchArea: undefined,
      searchText: "",
      areaList: [],

      currentPage: 1,
      currentPageSize: 10,
      form: {
        name: "",
        type: "",
        belongCateId: null,
        belongAreaId: undefined,
        imgUrls: []
      }

    };
  },
  created() {
    this.onFetchCate(this.currentPage, this.currentPageSize);
    this.onFetchArea();
  },
  methods: {
    onSearch() {
      this.currentPage = 1;
      this.$refs.PaginationBar.onSet(this.currentPage);
      this.onFetchCate(this.currentPage, this.currentPageSize);
    },
    onFetchArea() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminFetchAreaAll(
        that.$root.token,
      );
      request
        .then((res) => {
          that.areaList = res.data.data
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    // fetch all banner for list
    onFetchCate(page, pageSize) {
      let that = this;
      that.$root.boolLoading = true;
      const request = CateServices.adminFetchCate(
        page,
        pageSize,
        'level1',
        that.searchArea,
        that.searchText,
        that.$root.token
      );
      request
        .then((res) => {
          that.data = res.data.data.data;
          that.data.map((item) => {
            item.key = item.id;
          });
          that.total = res.data.data.total;
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },


    onChangePage(obj) {
      this.currentPage = obj.page;
      this.currentPageSize = obj.pageSize;
      this.onFetchCate(obj.page, obj.pageSize);
    },

    onStatus(item) {
      let that = this;
      var status = "on";
      if (item.status == "on") {
        status = "off";
      }
      that.$root.boolLoading = true;
      const request = CateServices.adminUpdateCateStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          if (that.form.id != undefined) {
            that.onFetchCateSecondAll(that.form.id);
          } else {
            that.onFetchCate(that.currentPage, that.currentPageSize);
          }
        });
    },

    onAdd() {
      this.boolFirst = true
      this.$refs.itemForm.onAdd();
    },
    onEdit(passItem) {
      this.boolFirst = false
      // second info add up
      this.onFetchCateSecondAll(passItem.id)

      this.$refs.itemForm.onEdit(passItem);
    },

    onCheck() {
      if (
        this.form.name.length == 0 ||
        this.form.belongAreaId == undefined
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    // when model confirm
    onConfirm(boolEdit) {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },
    // when add new record big cate
    onConfirmAdd() {
      let that = this;
      that.$root.boolLoading = true;
      that.form.type = 'level1'

      const request = CateServices.adminCreateCate(
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          // that.onFetchCate(this.currentPage, this.currentPageSize);
        });
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = CateServices.adminUpdateCate(
        that.form.id,
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
          // that.onFetchCate(this.currentPage, this.currentPageSize);
        });
    },

    // set the data for edit record
    onSet(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later
      setTimeout(() => {
        this.form = passItem;
        this.$root.boolLoading = false;
      }, 500);
    },
    // reset the model data
    onReset() {
      this.form = {
        name: "",
        type: "",
        belongCateId: null,
        belongAreaId: undefined,
        imgUrls: []
      };
      this.dataS = []
      this.onFetchCate(this.currentPage, this.currentPageSize);
    },


    onFetchCateSecondAll(id) {
      let that = this;
      that.boolSecond = true;
      const request = CateServices.adminFetchSecondCateAll(
        ['on', 'off'],
        id,
        null,
        that.$root.token
      );
      request
        .then((res) => {
          var cateList = []
          res.data.data.forEach(element => {
            if (element.belongCateId == id) {
              cateList.push(element)
            }
          });
          that.dataS = cateList
        })
        .finally(() => {
          that.boolSecond = false;
        });
    },

    // second
    onSecondAdd() {
      this.$refs.itemFormSecond.onAdd();
    },

    onEditSecond(passItem) {
      this.$refs.itemFormSecond.onEdit(passItem);
    },

    onResetSecond() {
      this.onFetchCateSecondAll(this.form.id);
      this.formSecond = {
        name: "",
        type: "level2",
        belongCateId: null,
        imgUrls: []
      };
      var list = [];
      this.$refs.uploadImg.onSetFileList(list);
    },

    onCheckSecond() {
      if (
        this.formSecond.name.length == 0 ||
        this.formSecond.imgUrls.length == 0
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },

    onConfirmSecond(boolEdit) {
      // check form before further operation
      if (!this.onCheckSecond()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        this.onConfirmSecondAdd();
      } else {
        this.onConfirmSecondEdit();
      }
    },
    onConfirmSecondAdd() {
      let that = this;
      that.$root.boolLoading = true;
      that.formSecond.type = 'level2'
      that.formSecond.belongCateId = that.form.id
      that.formSecond.belongAreaId = that.form.belongAreaId

      const request = CateServices.adminCreateCateSecond(
        that.formSecond,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          that.onResetSecond();
          that.$refs.itemFormSecond.onReset();
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchCateSecondAll(that.form.id);
        });
    },
    onConfirmSecondEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = CateServices.adminUpdateCate(
        that.formSecond.id,
        that.formSecond,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          that.onResetSecond();
          that.$refs.itemFormSecond.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
          that.onFetchCateSecondAll(that.form.id);
        });
    },

    onSetSecond(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later
      setTimeout(() => {
        this.formSecond = passItem;
        this.$refs.uploadImg.onSetFileList(passItem.imgUrls);
        this.$root.boolLoading = false;
      }, 500);
    },
    onChangeImg(data) {
      this.formSecond.imgUrls = data;
    },


  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
