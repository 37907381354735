import api from './api'
import utils from './utils'



const adminFetchSupport = (page, pageSize, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchSupport, {
      page,
      pageSize,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateSupportStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateSupport, {
      id,
      status,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminUpdateSupport = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateSupport, {
      id,
      name: form.name,
      type: form.type,
      textData: form.textData
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminCreateSupport = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateSupport, {
      name: form.name,
      type: form.type,
      textData: form.textData
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchSupport,
  adminUpdateSupportStatus,
  adminUpdateSupport,
  adminCreateSupport
}
