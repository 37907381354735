<template>
  <div>
    <img
      v-if="imgUrl != null && imgUrl != undefined"
      class="row-img"
      :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
      :src="imgUrl"
      @click="onPreview"
    />
    <a-modal
      :visible="show"
      :footer="null"
      @ok="show = false"
      @cancel="show = false"
    >
      <img
        v-if="imgUrl != null && imgUrl != undefined"
        class="row-img-preivew"
        :src="imgUrl"
      />
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ImageTool",
  props: {
    imgUrl: {
      type: String,
      default: null,
    },
    imgWidth: {
      type: Number,
      default: 50,
    },
    imgHeight: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    onPreview() {
      this.show = !this.show;
    },
  },
};
</script>

<style  scoped>
.row-img {
  cursor: pointer;
}
.row-img-preivew {
  width: 100%;
  height: auto;
}
</style>
