<template>
  <div>
    <a-card title="会员折扣" style="width: 100%">
      <a-input-number style="width: 250px;" v-model="memberNum" :min="0" placeholder="请输入会员折扣（0-1）" />
      <div style="margin-top:20px">
        <a-button type="primary" @click="onMemberChange">
          保存修改
        </a-button>
      </div>
    </a-card>
    <div style="margin-top:20px"></div>

    <a-card title="会员规则" style="width: 100%">
      <a-textarea v-model="memberText" placeholder="会员规则" :auto-size="{ minRows: 3, maxRows: 120 }" />
      <div style="margin-top:20px">
        <a-button type="primary" @click="onMemberTextChange">
          保存修改
        </a-button>
      </div>
    </a-card>
    <div style="margin-top:20px"></div>

    <a-card title="自提仓库" style="width: 100%">
      <a-textarea v-model="selfPick" placeholder="自提仓库文字" :auto-size="{ minRows: 3, maxRows: 120 }" />
      <div style="margin-top:20px">
        <a-button type="primary" @click="onSelfPickChange">
          保存修改
        </a-button>
      </div>
    </a-card>
    <div style="margin-top:20px"></div>

    <a-card title="其他操作" style="width: 100%">
      <a-button type="danger" @click="onLogOut">
        退出后台
      </a-button>
    </a-card>
  </div>
</template>

<script>
import ConfigServices from "@/services/ConfigServices";

export default {
  components: {
  },
  data() {
    return {
      memberNum: 1,
      delivery: "",
      memberText: "",
      selfPick: ""
    };
  },
  created() {
    this.onFetchConfig();

  },
  methods: {
    onFetchConfig() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminFetchConfig(
        that.$root.token
      );
      request
        .then((res) => {
          res.data.data.forEach(element => {
            if (element.type == 'member') {
              that.memberNum = element.num
            } else if (element.type == 'delivery') {
              that.delivery = element.textData[0]
            } else if (element.type == 'memberText') {
              that.memberText = element.textData[0]
            } else if (element.type == 'selfPick') {
              that.selfPick = element.textData[0]
            }
          });
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onMemberChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigNum(
        "member",
        that.memberNum,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onDeliveryChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigText(
        "delivery",
        [that.delivery],
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });

    },

    onSelfPickChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigText(
        "selfPick",
        [that.selfPick],
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onMemberTextChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigText(
        "memberText",
        [that.memberText],
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onLogOut() {
      this.$cookies.remove('topAdminKey')
      window.location.href = "https://top.freshgo.top/"
    }
  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
