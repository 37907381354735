import api from './api'
import utils from './utils'



const adminFetchConfig = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchConfig, {
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateConfigText = (type, textData, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateConfig, {
      type, 
      textData
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateConfigNum= (type, num, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateConfig, {
      type, 
      num
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchConfig,
  adminUpdateConfigText,
  adminUpdateConfigNum
}
