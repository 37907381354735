<template>
  <div class="clearfix">
    <a-modal
      width="80%"
      :maskClosable="false"
      :visible="boolForm"
      :title="title"
      :confirm-loading="boolLoading"
      @ok="onConfirm"
      @cancel="onReset"
    >
      <a-spin :spinning="$root.boolLoading">
        <slot />
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      boolLoading: false,
      boolForm: false,
      boolEdit: false,
    };
  },
  methods: {
    onAdd() {
      this.boolForm = true;
    },
    onEdit(passItem) {
      console.log(passItem);
      this.boolForm = true;
      this.boolEdit = true;
      this.$emit("onSet", passItem);
    },
    onConfirm() {
      this.$emit("onConfirm", this.boolEdit);
    },

    onReset() {
      this.boolForm = false;
      this.boolLoading = false;
      this.boolEdit = false;
      this.$emit("onReset");
    },
  },
};
</script>
<style scoped>
</style>