import api from './api'
import utils from './utils'



const adminFetchOrder = (page, pageSize, name = '', areaId = null, status = null ,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchOrder, {
      page,
      pageSize,
      name,
      areaId,
      status
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminUpdateOrderStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateOrder, {
      id,
      status,
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminUpdateOrder = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateOrder, {
      id,
      supportComment: form.supportComment
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchOrder,
  adminUpdateOrderStatus,
  adminUpdateOrder,
}
