<template>
  <div>
    <!-- tool bar -->
    <div class="tool-bar">
      <div class="tb-1">
        <div style="display:inline-block;vertical-align:middle ">
          <a-select placeholder="仓库" v-model="searchArea" style="width: 120px" :allowClear="true">
            <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle ">
          <a-select placeholder="订单状态" v-model="searchStatus" style="width: 120px" :allowClear="true">
            <a-select-option value="pending">
              待支付
            </a-select-option>
            <a-select-option value="paid">
              已支付
            </a-select-option>
            <a-select-option value="done">
              已完成
            </a-select-option>
            <a-select-option value="cancel">
              已取消
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left:10px;display:inline-block;vertical-align:middle">
          <a-input-search style="width: 300px" placeholder="搜索订单ID或用户电话" v-model="searchText" enter-button
            @search="onSearch" />
        </div>
      </div>
      <div class="tb-2">
      </div>
    </div>

    <!-- item form for add or edit  -->
    <ItemForm ref="itemForm" @onReset="onReset" @onConfirm="onConfirm" @onSet="onSet" title="订单状态">
      <div class="input-pad">
        <div class="input-label">订单状态：</div>
        <a-select placeholder="请选择状态" v-model="form.status" style="width: 120px" :allowClear="false">
          <a-select-option value="pending">
            待支付
          </a-select-option>
          <a-select-option value="paid">
            已支付
          </a-select-option>
          <a-select-option value="done">
            已完成
          </a-select-option>
        </a-select>
      </div>
    </ItemForm>

    <a-modal width="80%" title="支付截图" :visible="boolImg" @cancel="onImgOff" @ok="onImgOff">
      <img class="b-img" v-if="img.length > 0" :src="img" />
      <div v-else>-</div>
    </a-modal>

    <a-modal width="80%" title="员工备注" :visible="boolSupport" @cancel="onSupportOff" @ok="onSupportConfirm">
      <div class="input-pad">
        <div class="input-label">员工备注:</div>
        <a-input v-model="form.supportComment" placeholder="请输入员工备注" />
      </div>
    </a-modal>

    <a-modal width="80%" title="下单商品列表" :visible="boolProduct" @cancel="onProductOff" @ok="onProductOff">
      <div v-for="(item, index) in productList" :key="index">
        <div>
          <div class="bp-1">
            <img class="bp-img" :src="item.product.imgUrls[0].data" />
          </div>
          <div class="bp-2">
            <div>{{ item.product.name }}</div>
          </div>
          <div class="bp-3">
            <div>x {{ item.num }}</div>
          </div>
        </div>
        <div style="padding-top: 20px;"></div>
      </div>
    </a-modal>


    <a-table :pagination="false" :columns="columns" :data-source="data" bordered style="background-color:white"
      :scroll="{ x: 2000 }">
      <template slot="type" slot-scope="text">
        <div>
          <div v-if="text == 'delivery'"> 全款支付 </div>
          <div v-if="text == 'payAfter'"> 送货上门 </div>
          <div v-if="text == 'selfPick'"> 自提 </div>
        </div>
      </template>

      <template slot="userInfo" slot-scope="text">
        <div>
          {{ text.name }}
        </div>
      </template>
      <template slot="userInfoPhone" slot-scope="text">
        <div>
          {{ text.phone }}
        </div>
      </template>
      <template slot="userInfoComment" slot-scope="text, record">
        <div v-if="record.type == 'delivery'">
          {{ text.comment }}
        </div>
        <div v-else>
          {{ text.commentBuy }}
        </div>
      </template>


      <template slot="payType" slot-scope="text">
        <div>
          <div v-if="text == 'wx'"> 微信支付 </div>
          <div v-if="text == 'cash'"> 货到付款 </div>
          <div v-if="text == 'bank'"> 银行卡支付 </div>
          <div v-if="text == 'wait'"> - </div>
        </div>
      </template>
      <template slot="status" slot-scope="text">
        <div>
          <a-tag v-if="text == 'pending'" color="orange"> 待支付 </a-tag>
          <a-tag v-if="text == 'paid'" color="green"> 已支付 </a-tag>
          <a-tag v-if="text == 'done'" color="blue"> 已完成 </a-tag>
          <a-tag v-if="text == 'cancel'" color="red"> 已取消 </a-tag>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div>
          <span>
            <a @click="onCopy(record)">复制订单</a>
          </span>
          <span style="margin-left: 10px">
            <a @click="onProduct(record)">查看商品</a>
          </span>
          <span style="margin-left: 10px">
            <a @click="onPrev(record)">查看支付图</a>
          </span>

          <span style="margin-left: 10px">
            <a @click="onSupportComment(record)">员工备注</a>
          </span>
          <span style="margin-left: 10px" v-if="record.status != 'cancel'">
            <a @click="onEdit(record)">修改状态</a>
          </span>
          <span v-if="record.status != 'cancel'" style="margin-left: 10px">
            <a @click="onCancel(record)">取消订单</a>
          </span>
        </div>
      </template>
    </a-table>
    <div class="pagination-block">
      <PaginationBar ref="PaginationBar" :currentPageSize="currentPageSize" :total="total" @onChange="onChangePage" />
    </div>
  </div>
</template>

<script>
import StorageServices from "@/services/StorageServices";
import OrderInfoServices from "@/services/OrderInfoServices";

import PaginationBar from "@/components/PaginationBar.vue";
import ItemForm from "@/components/itemForm.vue";

export default {
  components: {
    PaginationBar,
    ItemForm
  },
  data() {
    return {
      data: [],
      total: 0,
      columns: [
        // {
        //   title: "仓库ID",
        //   dataIndex: "areaId",
        //   key: "areaId",
        //   width: 100,
        //   scopedSlots: { customRender: "areaId" },
        // },
        {
          title: "订单ID",
          dataIndex: "orderId",
          key: "orderId",
          width: 150,
        },
        {
          title: "下单时间",
          dataIndex: "created_at",
          key: "createdTime",
          width: 200,
        },
        {
          title: "订单类型",
          dataIndex: "type",
          key: "type",
          width: 100,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "用户姓名",
          dataIndex: "userInfo",
          key: "userInfo",
          width: 100,
          scopedSlots: { customRender: "userInfo" },
        },
        {
          title: "用户的电话",
          dataIndex: "userInfo",
          key: "userInfoPhone",
          width: 150,
          scopedSlots: { customRender: "userInfoPhone" },
        },
        {
          title: "订单价格",
          dataIndex: "price",
          key: "price",
          width: 100,
        },
        {
          title: "支付方式",
          dataIndex: "payType",
          key: "payType",
          width: 100,
          scopedSlots: { customRender: "payType" },
        },
        {
          title: "用户备注",
          dataIndex: "userInfo",
          key: "userInfoComment",
          width: 150,

          scopedSlots: { customRender: "userInfoComment" },
        },
        {
          title: "员工备注",
          dataIndex: "supportComment",
          key: "supportComment",
          width: 150,
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          fixed: 'right',
          width: 500,
          scopedSlots: { customRender: "operation" },
        },
      ],
      currentPage: 1,
      currentPageSize: 10,
      form: {
        id: null,
        supportComment: "",
        status: ""
      },
      img: "",
      searchText: "",
      searchArea: undefined,
      searchStatus: undefined,
      areaList: [],
      productList: [],
      boolImg: false,
      boolSupport: false,
      boolProduct: false

    };
  },
  created() {
    this.onFetchArea()
  },
  methods: {
    onFetchArea() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminFetchAreaAll(
        that.$root.token
      );
      request
        .then((res) => {
          that.areaList = res.data.data
          that.onFetchOrder(this.currentPage, this.currentPageSize);
        })
        .finally(() => {
        });
    },

    // fetch all banner for list
    onFetchOrder(page, pageSize) {
      let that = this;
      that.$root.boolLoading = true;
      const request = OrderInfoServices.adminFetchOrder(
        page,
        pageSize,
        that.searchText,
        that.searchArea,
        that.searchStatus,
        that.$root.token
      );
      request
        .then((res) => {
          that.data = res.data.data.data;
          // console.log(res)
          that.data.map((item) => {
            item.key = item.id;
          });
          that.total = res.data.data.total;
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onChangePage(obj) {
      this.currentPage = obj.page;
      this.currentPageSize = obj.pageSize;
      this.onFetchOrder(obj.page, obj.pageSize);
    },

    onStatus(item) {
      let that = this;
      var status = "on";
      if (item.status == "on") {
        status = "off";
      }
      that.$root.boolLoading = true;
      const request = OrderInfoServices.adminUpdateOrderStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchOrder(this.currentPage, this.currentPageSize);
        });
    },


    onAdd() {
      this.$refs.itemForm.onAdd();
    },
    onEdit(passItem) {
      this.$refs.itemForm.onEdit(passItem);
    },

    onCheck() {
      return true;
    },
    // when model confirm
    onConfirm(boolEdit) {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        // this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = OrderInfoServices.adminUpdateOrderStatus(
        that.form.id,
        that.form.status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
          // that.onFetchOrder(this.currentPage, this.currentPageSize);
        });
    },

    // set the data for edit record
    onSet(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later
      setTimeout(() => {
        this.form = passItem;
        this.$root.boolLoading = false;
      }, 500);
    },
    // reset the model data
    onReset() {
      this.form = {
        id: null,
        supportComment: "",
        status: ""
      };
      this.onFetchOrder(this.currentPage, this.currentPageSize);
    },

    onSearch() {
      this.currentPage = 1;
      this.$refs.PaginationBar.onSet(this.currentPage);
      this.onFetchOrder(this.currentPage, this.currentPageSize);
    },

    onPrev(record) {
      // console.log(record)
      this.boolImg = true
      this.img = record.imgUrls
    },

    onProduct(record) {
      // console.log(record)
      this.boolProduct = true
      this.productList = record.product
      console.log(this.productList)
    },

    onImgOff() {
      this.boolImg = false
      this.img = ""
    },

    onProductOff() {
      this.boolProduct = false
      this.productList = []
    },

    onCancel(record) {
      let that = this;
      that.$root.boolLoading = true;
      const request = OrderInfoServices.adminUpdateOrderStatus(
        record.id,
        'cancel',
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });

    },

    onCopy(record) {
      console.log(record)
      var orderType = ""
      switch (record.type) {
        case "payAfter":
          orderType = "送货上门"
          break;
        case "selfPick":
          orderType = "自提"
          break;
        case "delivery":
          orderType = "全款支付"
          break;
      }
      var text = "订单号：" + record.id + "\n下单时间：" + record.created_at + "\n订单类型：" +
        orderType + "\n价格：" + record.price + "\n客户名称：" + record.userInfo.name + "\n客户电话：" + "\n客户地址："
        + record.userInfo.address + "\n客户电话：" + record.userInfo.phone+ "\n客户备注：" + record.userInfo.comment
      this.$clipboard(text)
    },

    onSupportComment(record) {
      this.boolSupport = true
      this.form.supportComment = record.supportComment
      this.form.id = record.id

    },
    onSupportOff() {
      this.boolSupport = false
    },
    onSupportConfirm() {
      let that = this;
      that.$root.boolLoading = true;
      const request = OrderInfoServices.adminUpdateOrder(
        that.form.id,
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          that.$refs.itemForm.onReset();
          that.boolSupport = false
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    }

  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}

.b-img {
  width: 100%;
  height: auto;
}

.bp-img {
  width: 150px;
  height: 150px;
  display: inline-block;
  object-fit: contain;
}

.bp-1 {
  width: 20%;
  display: inline-block;
  vertical-align: top;
}

.bp-2 {
  width: 70%;
  display: inline-block;
  vertical-align: top;
}

.bp-3 {
  width: 10%;
  display: inline-block;
  vertical-align: top;
}
</style>
