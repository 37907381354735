import api from './api'
import utils from './utils'

const adminLogin = (username,password) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminLogin, {
      username,
      password,
    })
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminFetchConfig = () => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchConfig, {
    })
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateConfig = (type, textData = null, num = 1) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateConfig, {
      type,
      textData,
      num
    })
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminCheck = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCheck, {
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminLogin,
  adminFetchConfig,
  adminUpdateConfig,
  adminCheck
}
