<template>
  <div>
    <a-pagination
      show-size-changer
      show-quick-jumper
      :total="total"
      :defaultPageSize="currentPageSize"
      @showSizeChange="onShowSizeChange"
      @change="onChange"
      :current="page"
    />
  </div>
</template>

<script>
export default {
  name: "PaginationBar",
  props: {
    currentPageSize: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {},
  created() {},
  methods: {
    onShowSizeChange(current, pageSize) {
      this.page = current;
      this.$emit("onChange", { page: current, pageSize });
    },
    onChange(current, pageSize) {
      this.page = current;
      this.$emit("onChange", { page: current, pageSize });
    },
    onSet(page) {
      this.page = page;
    },
  },
};
</script>


<style scoped>
</style>
