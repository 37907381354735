import Vue from 'vue'
import VueRouter from 'vue-router'
import AccountView from '@/views/AccountView.vue'
import StorageView from '@/views/StorageView.vue'
import BannerView from '@/views/BannerView.vue'
import HomeView from '@/views/HomeView.vue'
import CateView from '@/views/CateView.vue'
import SupportView from '@/views/SupportView.vue'
import ProductView from '@/views/ProductView.vue'
import OrderView from '@/views/OrderView.vue'
import SettingView from '@/views/SettingView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: AccountView
  },
  {
    path: '/StorageView',
    name: 'StorageView',
    component: StorageView
  },
  {
    path: '/BannerView',
    name: 'BannerView',
    component: BannerView
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/CateView',
    name: 'CateView',
    component: CateView
  },
  {
    path: '/SupportView',
    name: 'SupportView',
    component: SupportView
  },
  {
    path: '/ProductView',
    name: 'ProductView',
    component: ProductView
  },
  {
    path: '/OrderView',
    name: 'OrderView',
    component: OrderView
  },
  {
    path: '/SettingView',
    name: 'SettingView',
    component: SettingView
  },
  
]

const router = new VueRouter({
  routes
})

export default router
