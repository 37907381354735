<template>
  <div>
    <a-card title="首页展示分类（二级分类）" style="width: 100%">
      <!-- <a-checkbox-group v-model="cateCheckList" :options="cateList" @change="onCateCheck" />
      <div style="margin-top:20px">
        <a-button type="primary" @click="onCateChange">
          保存修改
        </a-button>
      </div> -->
      <a-select placeholder="仓库" v-model="searchArea" style="width: 120px" :allowClear="true">
        <a-select-option v-for="(item) in areaList" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" style="margin-left:10px" @click="onOpenArea">
        修改该仓库首页分类
      </a-button>

      <a-modal width="80%" title="修改首页展示分类" :visible="boolArea" @ok="onConfirmCate" @cancel="onResetCate">
        <a-spin class="block" :spinning="boolLoading">
          <div>
            <div style="display: inline-block;">
              <a-select placeholder="二级分类" v-model="newIndexCate" style="width: 120px" :allowClear="true">
                <a-select-option v-for="(item) in indexCateList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div style="display: inline-block; margin-left:20px;">
              <a-button type="primary" @click="onAddIndexCate">
                增加二级分类
              </a-button>
            </div>

            <div style="margin-top:20px"></div>

            <a-table :pagination="false" :columns="columnsC" :data-source="dataC" bordered style="background-color:white">
              <template slot="operation" slot-scope="text, record">
                <div>
                  <span>
                    <a @click="onDeleteCate(record)">删除</a>
                  </span>
                </div>
              </template>
            </a-table>
          </div>
        </a-spin>
      </a-modal>
    </a-card>
    <div style="margin-top:20px"></div>

    <a-card title="首页广告板块" style="width: 100%">
      <div class="input-pad">
        <upload-img ref="uploadImg" @onChangeImg="onChangeImg" placeholderText="上传头像" :totalLength="1"
          sizeText="尺寸（375 宽 x150 高 ）" />
      </div>
      <div style="margin-top:20px">
        <a-button type="primary" @click="onAdChange">
          保存修改
        </a-button>
      </div>
    </a-card>

    <div style="margin-top:20px"></div>
    <a-card title="首页商品模块" style="width: 100%">
      <a-button type="primary" @click="onAddIndex">
        增加模块
      </a-button>
      <div style="margin-top:20px"></div>

      <a-table :pagination="false" :columns="columns" :data-source="data" bordered style="background-color:white">
        <template slot="operation" slot-scope="text, record">
          <div>
            <span>
              <a @click="onEdit(record)">编辑</a>
            </span>
            <span style="margin-left: 10px">
              <a @click="onDelete(record)">删除</a>
            </span>
          </div>
        </template>
      </a-table>

      <!-- model the module -->
      <a-modal width="80%" title="增加首页模块" :visible="boolIndex" @ok="onConfirm" @cancel="onReset">
        <a-spin class="block" :spinning="boolLoading">
          <div class="input-pad">
            <div class="input-label">模块名称：</div>
            <a-input v-model="form.name" placeholder="请输入模块名称" />
            <div style="margin-top:20px"></div>
            <div v-if="boolEdit">
              <div style="width:50%;display: inline-block;">
                <a-select mode="multiple" label-in-value :value="searchProduct" placeholder="请搜索添加关联商品"
                  style="width: 100%" :filter-option="false" :not-found-content="boolProduct ? undefined : null"
                  @search="onFetchProductByName" @change="onChangeProduct">
                  <a-spin v-if="boolProduct" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in productList" :key="d.id">
                    商品ID：{{ d.id }} ，商品名称：{{ d.name }}
                  </a-select-option>
                </a-select>
              </div>
              <div style="width:200px;display: inline-block;margin-left:20px">
                <a-button type="primary" @click="onAddProduct">
                  增加商品
                </a-button>
              </div>
            </div>
            <div style="margin-top:20px"></div>

            <a-table v-if="boolEdit" :pagination="false" :columns="columnsP" :data-source="dataP" bordered
              style="background-color:white">
              <template slot="status" slot-scope="text">
                <div>
                  <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
                  <a-tag v-else color="red"> 关闭 </a-tag>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record">
                <div>
                  <span>
                    <a @click="onDeleteProduct(record)">删除</a>
                  </span>
                </div>
              </template>
            </a-table>
          </div>
        </a-spin>
      </a-modal>
    </a-card>

  </div>
</template>

<script>
import ConfigServices from "@/services/ConfigServices";
import ProductServices from "@/services/ProductServices";
import CateServices from "@/services/CateServices";
import UploadImg from "@/components/uploadImg.vue";
import StorageServices from "@/services/StorageServices";
import IndexCateServices from "@/services/IndexCateServices";

export default {
  components: {
    UploadImg
  },
  data() {
    return {
      cateList: [],
      cateCheckList: [],
      boolIndex: false,
      boolEdit: false,
      boolLoading: false,

      form: {
        id: null,
        name: "",
        product: []
      },
      data: [],
      columns: [
        {
          title: "模块名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],

      dataP: [],
      columnsP: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "所属仓库",
          dataIndex: "areaName",
          key: "areaName",
          width: 150,
        },
        {
          title: "商品名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],

      formAd: {
        imgUrls: []
      },

      formCate: {
        belongAreaId: null,
        cateId: null
      },
      columnsC: [
        {
          title: "二级分类",
          dataIndex: "cateName",
          key: "cateName",
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataC: [],
      indexCateList: [],

      searchArea: undefined,
      areaList: [],
      boolProduct: false,
      boolArea: false,
      productList: [],
      searchProduct: [],
      searchProductList: [],
      newIndexCate: undefined
    };
  },
  created() {
    this.onFetchConfig();
  },
  methods: {

    onFetchArea() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminFetchAreaAll(
        that.$root.token,
      );
      request
        .then((res) => {
          that.areaList = res.data.data
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onFetchConfig() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminFetchConfig(
        that.$root.token
      );
      request
        .then((res) => {
          res.data.data.forEach(element => {
            if (element.type == 'index') {
              that.data = element.textData
            } else if (element.type == 'indexCate') {
              that.cateCheckList = element.textData
            } else if (element.type == 'ad') {
              try {
                that.formAd.imgUrls = element.textData
                that.$refs.uploadImg.onSetFileList(element.textData);
              } catch (error) {
                console.log(error)
              }
            }

          });
        })
        .finally(() => {
          // that.$root.boolLoading = false;
          that.onFetchArea()

        });
    },
    onFetchCate(belongAreaId) {
      let that = this;
      that.boolLoading = true;
      const request = CateServices.adminFetchSecondCateAll(
        ['on'],
        null,
        belongAreaId,
        that.$root.token
      );
      request
        .then((res) => {
          var cate = []
          res.data.data.forEach(element => {
            if (element.type == 'level2') {
              cate.push(element)
            }
          });
          that.indexCateList = cate;
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },
    onFetchProduct(ids) {
      let that = this;
      that.boolLoading = true;
      const request = ProductServices.adminFetchProductByIds(
        ids,
        ['on', 'off'],
        that.$root.token
      );
      request
        .then((res) => {
          that.dataP = res.data.data;
        })
        .finally(() => {
          that.boolLoading = false;
        });
    },

    onCateCheck(e) {
      console.log(e)
    },
    onCateChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigText(
        "indexCate",
        that.cateCheckList,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onOpenArea() {
      let that = this;
      if (that.searchArea == undefined) {
        that.$message.info("尚未选仓库信息");
        return
      }
      that.boolArea = true;
      that.boolLoading = true;

      const request = IndexCateServices.adminFetchIndexCate(
        that.searchArea,
        that.$root.token
      );
      request
        .then((res) => {
          console.log(res)
          that.dataC = res.data.data
        })
        .finally(() => {
          that.boolLoading = false;
          that.onFetchCate(that.searchArea)
        });
    },

    onAdChange() {
      let that = this;
      that.$root.boolLoading = true;
      const request = ConfigServices.adminUpdateConfigText(
        "ad",
        that.formAd.imgUrls,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    onAddIndex() {
      this.boolIndex = true
      var maxId = 0
      this.data.forEach((item) => {
        if (item.id > maxId) {
          maxId = item.id
        }
      })
      this.form.id = maxId + 1
    },

    onEdit(item) {
      this.onFetchProduct(item.product)
      this.boolEdit = true;
      this.boolIndex = true;
      this.form = item
    },

    onDelete(record) {
      let that = this;
      that.$root.boolLoading = true;
      var lst = []
      that.data.forEach((item) => {
        if (item.id != record.id) {
          lst.push(item)
        }
      })
      const request = ConfigServices.adminUpdateConfigText(
        "index",
        lst,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          that.onFetchConfig()
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },

    // when model confirm
    onConfirm() {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!this.boolEdit) {
        this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },

    // when add new record
    onConfirmAdd() {
      let that = this;
      that.$root.boolLoading = true;
      that.data.push(that.form)
      const request = ConfigServices.adminUpdateConfigText(
        "index",
        that.data,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          that.onReset();
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          // that.onFetchProduct(this.currentPage, this.currentPageSize);
        });
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      that.data.forEach((item) => {
        if (item.id == that.form.id) {
          item.name = that.form.name
          item.product = that.form.product
        }
      })
      const request = ConfigServices.adminUpdateConfigText(
        "index",
        that.data,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          that.onReset();

        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },


    onCheck() {
      if (
        this.form.name.length == 0
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    onReset() {
      this.boolIndex = false;
      this.boolEdit = false;
      this.form = {
        id: null,
        name: "",
        product: []
      }
    },

    onDeleteProduct(passItem) {
      var lst = []
      var productIds = []
      this.dataP.forEach((item) => {
        if (passItem.id != item.id) {
          lst.push(item)
          productIds.push(item.id)
        }
      })
      this.dataP = lst
      this.form.product = productIds
    },

    onChangeImg(data) {
      this.formAd.imgUrls = data;
    },

    onAddProduct() {
      var productIds = this.form.product

      this.searchProduct.forEach((item) => {
        if (this.form.product.indexOf(item.key) == -1) {
          productIds.push(item.key)
        }
      })
      this.form.product = productIds
      this.searchProduct = []
      this.onFetchProduct(this.form.product)
    },

    onFetchProductByName(text) {
      let that = this;
      that.boolProduct = true;
      const request = ProductServices.adminFetchProductByName(
        text,
        ['on', 'off'],
        that.$root.token
      );
      request
        .then((res) => {
          that.productList = res.data.data;
          that.productList.map((item) => {
            item.key = item.id;
          });
        })
        .finally(() => {
          that.boolProduct = false;
        });
    },

    onChangeProduct(value) {
      this.searchProduct = value
      this.productList = []
      this.boolProduct = false
    },

    onConfirmCate() {

    },
    onCheckCate() {
      if (
        this.formCate.belongAreaId == null ||
        this.formCate.cateId == null
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    onResetCate() {
      this.boolArea = false;
      this.formCate = {
        belongAreaId: null,
        cateId: null,
      }
    },
    onAddIndexCate() {
      let that = this;
      that.boolLoading = true;
      that.formCate.cateId = that.newIndexCate
      that.formCate.belongAreaId = that.searchArea
      const request = IndexCateServices.adminCreateIndexCate(
        that.formCate.belongAreaId,
        that.formCate.cateId,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          that.onResetCate();
        })
        .finally(() => {
          // refresh data
          that.boolLoading = false;
        });
    },
    onDeleteCate(data) {
      let that = this;
      that.boolLoading = true;
      const request = IndexCateServices.adminUpdateIndexCate(
        data.id,
        'deleted',
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          that.onResetCate();
        })
        .finally(() => {
          // refresh data
          that.boolLoading= false;
        });

    }

  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
