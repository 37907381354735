<template>
  <div class="login-content">
    <a-card class="login-block">
      <a-input v-model="form.username" placeholder="用户名" />
      <div class="holder"></div>
      <a-input-password v-model="form.password" placeholder="密码" />
      <div class="holder"></div>
      <a-button class="login-btn" type="primary" @click="onLogin">登录</a-button>
    </a-card>
  </div>
</template>

<script>
import AdminServices from "@/services/AdminServices";

export default {
  components: {
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },

  methods: {
    onLogin() {
      let that = this;
      const request = AdminServices.adminLogin(
        that.form.username,
        that.md5(that.form.password)
      );
      request
        .then((res) => {
          if(res.data.code == 500){
            that.$message.warning('账号密码错误');
          } else{
            that.$cookies.set('topAdminKey',res.data.data.token, '7d');
            that.$emit('onCheckKey')
          }
        })
        .finally(() => {
        });
    },
  }
}
</script>
 
<style scoped >
.login-content {
  width: 100%;
  display: inline-block;
  text-align: center;

}

.holder {
  padding-top: 20px;
}

.login-block {
  width: 600px;
  height: auto;
  position: fixed;
  top: 200px;
  margin-left: -300px;
  display: inline-block;
}

.login-btn {
  width: 100%;
}
</style>
  
<!--   
<template>
  <div>
    <a-card class="login-block">
      <a-cascader multiple :options="options" @change="onChange" placeholder="Please select" />
      <a-button block @click="onChange(1)">Default</a-button>
    </a-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: 'zhejiang',
          label: '浙江省',
          children: [
            {
              value: 'hangzhou',
              label: '杭州市',
              children: [
                {
                  value: 'xihu',
                  label: '西湖区',
                },
              ],
            },
          ],
        },
        {
          value: 'shanghai',
          label: '上海市',
          children: [
            {
              value: 'qingpuqu',
              label: '青浦区',
              children: [
                {
                  value: 'beijinglu',
                  label: '北京路',
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    onChange(v) {
      console.log(v);
    },
  },
};
</script>
<style scoped >
.login-content {
  width: 100%;
  display: inline-block;
  text-align: center;

}

.holder {
  padding-top: 20px;
}

.login-block {
  width: 600px;
  height: auto;
  position: fixed;
  top: 200px;
  margin-left: -300px;
  display: inline-block;
}

.login-btn {
  width: 100%;
}
</style> -->

