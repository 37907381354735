import api from './api'
import utils from './utils'



const adminFetchIndexCate = (belongAreaId,token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchIndexCate, {
      belongAreaId,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const adminUpdateIndexCate = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateIndexCate, {
      id,
      status
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminCreateIndexCate = (belongAreaId,cateId, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateIndexCate, {
      belongAreaId,
      cateId
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchIndexCate,
  adminUpdateIndexCate,
  adminCreateIndexCate
}
