import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import MD5 from 'js-md5';
import cookies from 'vue-cookies'

import VMdEditor from '@kangc/v-md-editor';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';
// highlightjs
import hljs from 'highlight.js';
import axios from 'axios' //引入axios
import router from './router'

import Clipboard from'v-clipboard';
Vue.prototype.$axios = axios; //添加axios到Vue的原型对象上


Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(Clipboard);

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdEditor)

Vue.prototype.md5 = MD5
Vue.prototype.$cookies = cookies;

Vue.prototype.productionTip

axios.defaults.baseURL = '/api/v1'



new Vue({
  router,
  data: function () {
    return {
      boolLogin: false,
      boolLoading: false,
      token: this.$cookies.get("topAdminKey"),
    }
  },
  render: h => h(App)
}).$mount('#app')
