import api from './api'
import utils from './utils'



const adminFetchCateAll = (token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchCateAll, {
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



const adminFetchSecondCateAll = (status, belongCateId = null, belongAreaId = null, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchSecondCateAll, {
      status,
      belongCateId,
      belongAreaId
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminFetchCate = (page, pageSize, level, area, text, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchCate, {
      page,
      pageSize,
      level,
      area,
      text
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminCreateCate = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateCate, {
      type: form.type,
      name: form.name,
      imgUrls: form.imgUrls,
      belongCateId: null,
      belongAreaId: form.belongAreaId
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminCreateCateSecond = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateCate, {
      type: form.type,
      name: form.name,
      imgUrls: form.imgUrls,
      belongCateId: form.belongCateId,
      belongAreaId: form.belongAreaId
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateCate = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateCate, {
      id,
      name: form.name,
      imgUrls: form.imgUrls
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


const adminUpdateCateStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateCate, {
      id,
      status
    }, token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}


export default {
  adminFetchCateAll,
  adminFetchCate,
  adminCreateCate,
  adminUpdateCate,
  adminCreateCateSecond,
  adminUpdateCateStatus,
  adminFetchSecondCateAll,
}
