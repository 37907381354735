<template>
  <div>
    <!-- tool bar -->
    <div class="tool-bar">
      <div class="tb-1"></div>
      <div class="tb-2">
        <a-button @click="onAdd" type="primary">
          创建新仓库
        </a-button>
      </div>
    </div>

    <!-- item form for add or edit  -->
    <ItemForm ref="itemForm" @onReset="onReset" @onConfirm="onConfirm" @onSet="onSet" title="仓库管理">
      <div class="input-pad">
        <div class="input-label">仓库名称：</div>
        <a-input v-model="form.name" placeholder="请输入仓库名称" />
      </div>
      <div class="input-pad">
        <div class="input-label">邮费：</div>
        <a-input-number style="width: 200px;" v-model="form.delivery" :min="0" placeholder="请输入邮费" />
      </div>
      <div class="input-pad">
        <div class="input-label">免邮额度：</div>
        <a-input-number style="width: 200px;" v-model="form.threshold" :min="0" placeholder="请输入免邮额度" />
      </div>
      <div class="input-pad">
        <div class="input-label">配送规则：</div>
        <a-textarea v-model="form.deliveryText" placeholder="本仓库的配送文字" :auto-size="{ minRows: 3, maxRows: 120 }" />
      </div>
      <!-- <div class="input-pad">
        <div class="input-label">微信支付是否开启：</div>
        <a-switch v-model="form.wx" />
      </div> -->

    </ItemForm>

    <a-table :pagination="false" :columns="columns" :data-source="data" bordered style="background-color:white">
      <template slot="wx" slot-scope="text">
        <div>
          <div v-if="text == true"> 开启 </div>
          <div v-else> 关闭 </div>
        </div>
      </template>
      <template slot="status" slot-scope="text">
        <div>
          <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
          <a-tag v-else color="red"> 关闭 </a-tag>
        </div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <div>
          <span>
            <a @click="() => onStatus(record)">{{
              record.status == "on" ? "下架" : "上架"
            }}</a>
          </span>
          <span style="margin-left: 10px">
            <a @click="onEdit(record)">编辑</a>
          </span>
        </div>
      </template>
    </a-table>
    <div class="pagination-block">
      <PaginationBar ref="PaginationBar" :total="total" @onChange="onChangePage" />
    </div>
  </div>
</template>

<script>
import PaginationBar from "@/components/PaginationBar.vue";
import StorageServices from "@/services/StorageServices";
import ItemForm from "@/components/itemForm.vue";

export default {
  components: {
    PaginationBar,
    ItemForm
  },
  data() {
    return {
      data: [],
      total: 0,
      columns: [
        {
          title: "仓库名称",
          dataIndex: "name",
          key: "name",
        },
        // {
        //   title: "微信支付状态",
        //   dataIndex: "wx",
        //   key: "wx",
        //   scopedSlots: { customRender: "wx" },
        // },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      currentPage: 1,
      currentPageSize: 20,
      form: {
        name: "",
        deliveryText: "",
        threshold: 0,
        delivery: 0,
        payment: {},
        wx: false
      }
    };
  },
  created() {
    this.onFetchStorage(this.currentPage, this.currentPageSize);
  },
  methods: {
    // fetch all banner for list
    onFetchStorage(page, pageSize) {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminFetchArea(
        page,
        pageSize,
        that.$root.token
      );
      request
        .then((res) => {
          that.data = res.data.data.data;
          // console.log(res)
          that.data.map((item) => {
            item.key = item.id;
          });
          that.total = res.data.data.total;
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onChangePage(obj) {
      this.currentPage = obj.page;
      this.currentPageSize = obj.pageSize;
      this.onFetchStorage(obj.page, obj.pageSize);
    },

    onStatus(item) {
      let that = this;
      var status = "on";
      if (item.status == "on") {
        status = "off";
      }
      that.$root.boolLoading = true;
      const request = StorageServices.adminUpdateAreaStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchStorage(this.currentPage, this.currentPageSize);
        });
    },


    onAdd() {
      this.$refs.itemForm.onAdd();
    },
    onEdit(passItem) {
      this.$refs.itemForm.onEdit(passItem);
    },

    onCheck() {
      if (
        this.form.name.length == 0
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    // when model confirm
    onConfirm(boolEdit) {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },
    // when add new record
    onConfirmAdd() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminCreateArea(
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          // that.onFetchStorage(this.currentPage, this.currentPageSize);
        });
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = StorageServices.adminUpdateArea(
        that.form.id,
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
          // that.onFetchStorage(this.currentPage, this.currentPageSize);
        });
    },

    // set the data for edit record
    onSet(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later
      setTimeout(() => {
        this.form = passItem;
        this.$root.boolLoading = false;
      }, 500);
    },
    // reset the model data
    onReset() {
      this.form = {
        name: "",
        deliveryText: "",
        threshold: 0,
        delivery: 0,
        payment: {},
        wx: false
      };
      this.onFetchStorage(this.currentPage, this.currentPageSize);
    },

  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
