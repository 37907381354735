<template>
  <div>
    <!-- tool bar -->
    <div class="tool-bar">
      <div class="tb-1"></div>
      <div class="tb-2">
        <a-button @click="onAdd" type="primary">
          创建Banner
        </a-button>
      </div>
    </div>

    <!-- item form for add or edit  -->
    <ItemForm ref="itemForm" @onReset="onReset" @onConfirm="onConfirm" @onSet="onSet" title="Banner管理">
      <div class="input-pad">
        <upload-img ref="uploadImg" @onChangeImg="onChangeImg" placeholderText="上传头像" :totalLength="1"
          sizeText="尺寸（375 宽 x 200 高）" />
      </div>
    </ItemForm>

    <a-table :pagination="false" :columns="columns" :data-source="data" bordered style="background-color:white">
      <template slot="imgUrls" slot-scope="imgUrls">
        <div class="editable-row-operations">
          <div class="img-list" v-for="(item, index) in imgUrls" :key="index">
            <image-tool v-if="item.response != undefined" :imgUrl="item.response.data"></image-tool>
            <div v-else>-</div>
          </div>
        </div>
      </template>

      <template slot="status" slot-scope="text">
        <div>
          <a-tag v-if="text == 'on'" color="blue"> 正常 </a-tag>
          <a-tag v-else color="red"> 关闭 </a-tag>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div>
          <span>
            <a @click="() => onStatus(record)">{{
              record.status == "on" ? "下架" : "上架"
            }}</a>
          </span>
          <span style="margin-left: 10px">
            <a @click="onEdit(record)">编辑</a>
          </span>
              
          <span style="margin-left: 10px">
              <a @click="onDelete(record)">删除</a>
            </span>

        </div>
      </template>
    </a-table>
    <div class="pagination-block">
      <PaginationBar ref="PaginationBar" :total="total" @onChange="onChangePage" />
    </div>
  </div>
</template>

<script>
import PaginationBar from "@/components/PaginationBar.vue";
import BannerServices from "@/services/BannerServices";
import ItemForm from "@/components/itemForm.vue";
import ImageTool from "@/components/imageTool.vue";
import UploadImg from "@/components/uploadImg.vue";

export default {
  components: {
    PaginationBar,
    ItemForm,
    ImageTool,
    UploadImg
  },
  data() {
    return {
      data: [],
      total: 0,
      columns: [
        {
          title: "图片",
          dataIndex: "imgUrls",
          key: "imgUrls",
          scopedSlots: { customRender: "imgUrls" },
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      currentPage: 1,
      currentPageSize: 20,
      form: {
        imgUrls: []
      }
    };
  },
  created() {
    this.onFetchBanner(this.currentPage, this.currentPageSize);
  },
  methods: {
    // fetch all banner for list
    onFetchBanner(page, pageSize) {
      let that = this;
      that.$root.boolLoading = true;
      const request = BannerServices.adminFetchBanner(
        page,
        pageSize,
        that.$root.token
      );
      request
        .then((res) => {
          that.data = res.data.data.data;
          // console.log(res)
          that.data.map((item) => {
            item.key = item.id;
          });
          that.total = res.data.data.total;
        })
        .finally(() => {
          that.$root.boolLoading = false;
        });
    },
    onChangePage(obj) {
      this.currentPage = obj.page;
      this.currentPageSize = obj.pageSize;
      this.onFetchBanner(obj.page, obj.pageSize);
    },

    onStatus(item) {
      let that = this;
      var status = "on";
      if (item.status == "on") {
        status = "off";
      }
      that.$root.boolLoading = true;
      const request = BannerServices.adminUpdateBannerStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchBanner(this.currentPage, this.currentPageSize);
        });
    },


    onChangeImg(data) {
      this.form.imgUrls = data;
    },

    onAdd() {
      this.$refs.itemForm.onAdd();
    },
    onEdit(passItem) {
      this.$refs.itemForm.onEdit(passItem);
    },

    onCheck() {
      if (
        this.form.imgUrls.length == 0
      ) {
        this.$message.info("尚有未填信息");
        return false;
      }
      return true;
    },
    // when model confirm
    onConfirm(boolEdit) {
      // check form before further operation
      if (!this.onCheck()) {
        return;
      }
      // if it is edit mode or insert mode
      if (!boolEdit) {
        this.onConfirmAdd();
      } else {
        this.onConfirmEdit();
      }
    },
    // when add new record
    onConfirmAdd() {
      let that = this;
      that.$root.boolLoading = true;
      const request = BannerServices.adminCreateBanner(
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // reset the model
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          // that.onFetchBanner(this.currentPage, this.currentPageSize);
        });
    },

    // when edit record
    onConfirmEdit() {
      let that = this;
      that.$root.boolLoading = true;
      const request = BannerServices.adminUpdateBanner(
        that.form.id,
        that.form,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
          // that.onReset();
          that.$refs.itemForm.onReset();
        })
        .finally(() => {
          that.$root.boolLoading = false;
          // that.onFetchBanner(this.currentPage, this.currentPageSize);
        });
    },

    // set the data for edit record
    onSet(passItem) {
      this.$root.boolLoading = true;
      // because the uploadImg is inside the dialog, so the refs was generated later
      setTimeout(() => {
        this.form = passItem;
        this.$refs.uploadImg.onSetFileList(passItem.imgUrls);
        this.$root.boolLoading = false;
      }, 500);
    },
    // reset the model data
    onReset() {
      this.form = {
        imgUrls: []
      };
      var list = [];
      this.$refs.uploadImg.onSetFileList(list);
      this.onFetchBanner(this.currentPage, this.currentPageSize);
    },

    onDelete(item) {
      let that = this;
      var status = "deleted";
      that.$root.boolLoading = true;
      const request = BannerServices.adminUpdateBannerStatus(
        item.id,
        status,
        that.$root.token
      );
      request
        .then(() => {
          that.$message.success("操作成功");
        })
        .finally(() => {
          // refresh data
          that.$root.boolLoading = false;
          that.onFetchBanner(this.currentPage, this.currentPageSize);
        });
    },

  }
}
</script>


<style scoped>
.pagination-block {
  margin: 10px 0px;
}

.content-block {
  margin: 0px 10px;
}

.head-text {
  font-weight: bold;
  font-size: 18px;
}

.input-block {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.input-pad {
  margin: 10px 0px;
}

.input-label {
  margin: 10px 0px;
}

.btn-block {
  width: 28%;
  margin-left: 1.5%;
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.btn-pad {
  margin: 0px 10px;
}

.img-list {
  display: inline-block;
  padding: 0px 10px;
}

.tool-bar {
  width: 100%;
  display: inline-block;
  padding: 20px 0px;
}

.tb-1 {
  width: 70%;
  display: inline-block;
}

.tb-2 {
  width: 30%;
  display: inline-block;
  text-align: right;
}
</style>
