<template>
  <div>
    <a-spin class="block" :spinning="boolLoading">
      <a-layout style="min-height: 100vh" v-if="boolLogin">
        <a-layout-sider>
          <a-menu :default-selected-keys="current" :style="{ height: '100%', borderRight: 0 }" theme="light" mode="inline">
            <a-menu-item key="Index" @click="onNavTo('')">
              <span>用户列表</span>
            </a-menu-item>
            <a-menu-item key="OrderView" @click="onNavTo('OrderView')">
              <span>订单列表</span>
            </a-menu-item>
            <a-menu-item key="HomeView" @click="onNavTo('HomeView')">
              <span>首页设置</span>
            </a-menu-item>
            <a-menu-item key="BannerView" @click="onNavTo('BannerView')">
              <span>Banner管理</span>
            </a-menu-item>
            <a-menu-item key="CateView" @click="onNavTo('CateView')">
              <span>分类管理</span>
            </a-menu-item>
            <a-menu-item key="ProductView" @click="onNavTo('ProductView')">
              <span>商品管理</span>
            </a-menu-item>
            <a-menu-item key="StorageView" @click="onNavTo('StorageView')">
              <span>仓库管理</span>
            </a-menu-item>
            <a-menu-item key="SupportView" @click="onNavTo('SupportView')">
              <span>客服管理</span>
            </a-menu-item>
            <a-menu-item key="SettingView" @click="onNavTo('SettingView')">
              <span>其他操作</span>
            </a-menu-item>

          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-content  style="margin: 20px;">
            <a-spin :spinning="$root.boolLoading">
              <router-view />
            </a-spin>
          </a-layout-content>
        </a-layout>
      </a-layout>
      <loginForm v-else ref="login" @onCheckKey="onCheckKey" />
    </a-spin>
  </div>
</template>

<script>
import loginForm from "@/components/loginForm.vue";
import AdminServices from "@/services/AdminServices";
export default {
  name: 'App',
  components: {
    loginForm

  },
  data() {
    return {
      boolLogin: false,
      boolLoading: false,
      current: ["Index"],
    };
  },
  created() {
    this.onCheckKey()
    // refresh result to the last page
    this.current = [this.$route.name];
  },
  methods: {
    async onNavTo(name) {
      // check auth before routing
      if (this.$route.name == name) {
        return;
      }
      // index page also equal to empty
      if (this.$route.name == "Index" && name == "") {
        return;
      }

      this.$router.push("/" + name);
    },
    onCheckKey() {
      let that = this;
      that.boolLoading = true
      var token = that.$cookies.get('topAdminKey')
      const request = AdminServices.adminCheck(token);
      request
        .then((res) => {
          if (res.data.code == 200) {
            that.boolLogin = true
            that.$root.token = token
          }
        })
        .finally(() => {
          that.boolLoading = false
        });
    }
  }
}
</script>

<style scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
</style>