import api from './api'
import utils from './utils'



const adminFetchBanner = (page, pageSize, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminFetchBanner, {
      page,
      pageSize,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const adminUpdateBannerStatus = (id, status, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateBanner, {
      id,
      status,
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminUpdateBanner = (id, form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminUpdateBanner, {
      id,
      imgUrls: form.imgUrls
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
const adminCreateBanner = (form, token) => {
  return new Promise((resolve, reject) => {
    const request = utils.postData(api.adminCreateBanner, {
      imgUrls: form.imgUrls
    },token)
    request.then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}



export default {
  adminFetchBanner,
  adminUpdateBannerStatus,
  adminUpdateBanner,
  adminCreateBanner
}
